<template>
	<div class="ns-brand">
		<el-carousel height="400px" v-loading="loadingAd">
			<el-carousel-item v-for="item in adList" :key="item.adv_id">
				<el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" />
			</el-carousel-item>
		</el-carousel>

		<!-- 品牌列表 -->
		<div class="ns-brand-box" v-loading="loading">
			<div>
				<!-- <div class="ns-brand-title-wrap ns-text-align">
					<p class="ns-brand-title">需求大厅</p>
					<img src="@/assets/images/goods/split.png" alt="" />
					<p class="ns-brand-en">Supply Hall</p>
				</div> -->

				<div class="ns-brand-list" v-if="brandList.length > 0">
					<table style="border-collapse: collapse;border-spacing: 0;width: 100%;">
						<thead>
							<tr class="supply_th_tr">
								<th style="width: 30%;">标题</th>
								<th style="width: 50%;text-align: center !important;">项目描述</th>
				  		<th>报名截止日期</th>
								<!-- <th>采购商信息</th> -->
								<th>操作&nbsp;</th>
							</tr>
						</thead>
						<tbody v-for="(item, index) in brandList" :key="index">
							<tr class="supply_tr">
								<td class="supply_td" style="width: 30% !important;text-align: left;">
									<el-image style="width: 60px;height: 60px;float: left;padding-right: 15px;" fit="scale-down"
										:src="$img(item.goods_image)" lazy @error="imageError(index)"></el-image>
									<div class="purchase-hd">
										<a @click="$router.pushToTab({ path: '/supply_detail', query: { id: item.goods_id } })" target="_blank"
											title="{{ item.goods_name }}">{{ item.goods_name }}</a>
										<p>发布日期：{{ item.create_time }}</p>
									</div>
								</td>
								<td class="supply_td" style="width: 50% !important;text-align: center;">{{ item.goods_describe }} </td>
								<td class="supply_td">
									<div>
										{{ item.end_time }}
									</div>
								</td>
								<!-- <td class="supply_td"> {{item.user_name}} </td> -->
								<td class="supply_td"> <a style="background-color: orange;padding: 10px;"
										target="_blank" @click="$router.pushToTab({ path: '/supply_detail', query: { id: item.goods_id } })" v-if="item.is_end==true&&item.goods_state!=3">立即报名</a>
										<a v-else-if="item.goods_state==1" style="background-color: gray !important;padding: 10px;">正在对接</a>
										<a v-else-if="item.goods_state==2" style="background-color: gray !important;padding: 10px;">正在实施</a>
										<a style="background-color: orange;padding: 10px;"
										target="_blank" @click="$router.pushToTab({ path: '/supply_detail', query: { id: item.goods_id } })" v-if="item.goods_state==3">项目完成</a>
										<!-- <a style="background-color: orange;padding: 10px;"
										target="_blank" @click="$router.pushToTab({ path: '/supply_detail', query: { id: item.goods_id } })" v-else-if="item.goods_state==4">立即报名</a> -->
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<!--<div class="ns-brand-list" v-if="brandList.length > 0">
          <div class="ns-brand-li" v-for="(item, index) in brandList" :key="index" @click="$router.pushToTab({ path: '/list', query: { brand_id: item.brand_id } })">
            <div class="ns-brand-wrap">
              <el-image fit="scale-down" :src="$img(item.image_url)" lazy @error="imageError(index)"></el-image>
              <p :title="item.brand_name">{{ item.brand_name }}</p>
            </div>
          </div>
        </div>-->
				<div class="empty-wrap" v-if="brandList.length <= 0">
					<div class="ns-text-align">暂无信息发布,去首页看看吧</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		SupplyList
	} from '@/api/goods/goods';
	import {
		mapGetters
	} from 'vuex';
	import {
		adList
	} from '@/api/website';

	export default {
		name: 'supply',
		components: {},
		data: () => {
			return {
				total: 0,
				currentPage: 1,
				pageSize: 20,
				brandList: [],
				siteId: 0,
				loading: true,
				loadingAd: true,
				state:true,
				adList: []
			};
		},
		created() {
			this.getAdList();
			this.getSupplyList();
		},
		computed: {
			...mapGetters(['defaultBrandImage'])
		},
		methods: {
			handlePageSizeChange(size) {
				this.pageSize = size;
				this.refresh();
			},
			handleCurrentPageChange(page) {
				this.currentPage = page;
				this.refresh();
			},
			refresh() {
				this.loading = true;
				this.getSupplyList();
			},
			getSupplyList() {
				SupplyList({
						page_size: this.pageSize,
						page: this.currentPage,
					})
					.then(res => {
						console.log(res);
						this.brandList = res.data.list;
						this.total = res.data.count;
						this.loading = false;
					})
					.catch(err => {
						this.loading = false;
						this.$message.error(err.message);
					});
			},
			/**
			 * 图片加载失败
			 */
			imageError(index) {
				this.brandList[index].image_url = this.defaultBrandImage;
			},
			getAdList() {
				adList({
						keyword: 'SUPPLY_DEMAND'
					})
					.then(res => {
						this.adList = res.data.adv_list;
						for (let i = 0; i < this.adList.length; i++) {
							if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url);
						}
						this.loadingAd = false;
					})
					.catch(err => {
						this.loadingAd = false;
					});
			}
		}
	};
</script>
<style lang="scss" scoped>
	.supply_th_tr {
		height: 40px;
		width: 100%;
		padding-top: 5px;
		border-bottom: 1px solid #cecbcb;
	}

	.supply_th_tr th {
		width: 10%;
		line-height: 30px;
		text-align: left;
	}

	.supply_tr {
		height: 80px;
		width: 100%;
		padding-top: 5px;
		border-bottom: 1px solid #cecbcb;
	}

	.supply_td {
		width: 10%;
		line-height: 30px;
		text-align: left;
	}

	.ns-text-align {
		text-align: center;
	}

	.ns-brand-box {
		width: 100%;
		background: #ffffff;

		>div {
			width: $width;
			margin: 0 auto;
		}
	}

	.ns-brand-title-wrap {
		padding-top: 54px;

		.ns-brand-title {
			font-size: 26px;
			font-weight: 600;
			line-height: 30px;
		}

		.ns-brand-en {
			font-size: 24px;
			font-weight: 600;
			color: #383838;
			opacity: 0.2;
			text-transform: uppercase;
			letter-spacing: 5px;
			line-height: 30px;
		}
	}

	.ns-brand-list {
		display: flex;
		flex-wrap: wrap;
		padding-top: 30px;

		.ns-brand-li {
			width: 20%;
			padding: 8px 6px;
			box-sizing: border-box;

			.ns-brand-wrap {
				width: 100%;
				border: 1px solid #f1f1f1;
				overflow: hidden;
				color: #303133;
				padding: 15px;
				box-sizing: border-box;
				cursor: pointer;
				text-align: center;

				.el-image {
					width: 100%;
					height: 120px;
					line-height: 120px;
				}

				p {
					font-size: 22px;
					color: #383838;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}
	}

	.empty-wrap {
		margin-top: 30px;
	}
</style>
<style lang="scss">
	.ns-brand {
		.el-carousel {
			.el-image__inner {
				width: auto;
			}
		}

		.el-carousel__arrow--right {
			right: 60px;
		}
	}
</style>
